import { graphql } from 'gatsby'
import React from 'react'
import talkCover from '../assets/images/talks.jpg'
import Comments from '../components/Comments/Comments'
import Layout from '../components/Layout'
import { SEO } from '../components/SEO'
import Talk from '../components/Talk'
import { PageBody } from '../components/common/PageBody'

export default function Talks(props) {
  const {
    data: {
      allMarkdownRemark: { edges: talks },
    },
  } = props

  return (
    <Layout>
      <SEO pageTitle="Talks" />
      <PageBody>
        <h2>Trung's Talks</h2>
        <img alt="Trung's Talks" src={talkCover} />
        <p>
          As a believer in the power of knowledge sharing, I'm excited to share
          with you some of my recent talks on various topics related to web
          development.
        </p>
        <p>
          See all of my previous 👉{' '}
          <a target="_blank" href="https://trungvose.com/talk-videos">
            recorded talks
          </a>
        </p>
        <div className="talks-container">
          {talks.map(({ node: talk }) => (
            <Talk key={talk.fields.slug} talk={talk} />
          ))}
        </div>
        <hr />
        <h2>Speaking at Your Event</h2>
        <p>
          If you’d like to engage me to speak at your event, you can reach me
          via
        </p>
        <ul>
          <li>
            <a href="https://twitter.com/trungvose">@trungvose</a>
          </li>
          <li>
            Send me a <a href="http://m.me/trungvose">message</a>
          </li>
          <li>
            Send me an <a href="mailto:trungvose@gmail.com">email</a>
          </li>
        </ul>
        <p>Thank you! Cảm ơn bạn rất nhiều :)</p>
        <Comments />
      </PageBody>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TalksPageQuery {
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "talk" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            organizerLogo
            title
            description
            date
            location
            info
            video
            slide
          }
        }
      }
    }
  }
`
